<template>
    <div>
      <div class="title-wrap">
        <div class="title">
          About
        </div>
      </div>
      <div class="background-img"></div>
      <div class="content-wrap">
        <div class="round-box">
          <div class="small-title">
            About Winex
          </div>
          <div class="main-title">
            <p>세계 최초 파인 와인 NFT 거래소</p>
            <b>"와넥스의 세계는 지금 이 순간도 확장되고 있습니다"</b>
          </div>
          <div class="why-winex-box">
            <div class="graph-img"><img src="~@/assets/images/about-graph.png"/></div>
            <div class="textarea">
              <div class="title">
                Why Winex
              </div>
              <div class="text">
                <p class="bold">파인 와인은 투자 가치가 있는 안전자산입니다</p>
                <p>
                  파인 와인은 소비량에 비해 공급량이 항상 부족하고 시간이 흐를수록 맛과 향 그리고 고유의 가치가 높아집니다. 주식은 발행량이 늘어나지만, 와인은 같은 빈티지를 마시면 점점 줄어들기 때문에 더욱 가치가 상승합니다. 또한 희소성 때문에 매년 가격이 우상향하므로 높은 수익이 기대되고 규격화되어 있는 아파트와 유사한 안전자산입니다.<br/>
                  파인 와인의 투자가치는 이미 역사적으로 입증 되었으며, 그동안 어려웠던 파인 와인 투자를 최신 NFT 기술로 편리하게 해보세요.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="about-advantage-box">
          <div class="title">
            Advantages of Winex
          </div>
          <div class="grid-wrap">
            <div class="grid-items">
            <div class="item" v-for="advantage in advantageList" v-bind:key="advantage.title">
              <div class="icon">
                <img :src="advantage.icon"/>
              </div>
              <div class="subject subject-pc">
                {{advantage.title}}
              </div>
              <div class="subject subject-mo">
                {{advantage.titleMo}}
              </div>
              <div class="text">
                {{advantage.text}}
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div class="container-gray">
        <div class="how-it-works">
          <div class="title">
            How it Works
          </div>
          <div class="content">
            <div class="content-pc">
              <div class="image">
                <img src="~@/assets/images/how-it-works20220720.png"/>
              </div>
            </div>
            <div class="content-tablet">
              <img src="~@/assets/images/tablet-how-it-works20220720.png">
            </div>
            <div class="content-mobile">
              <img src="~@/assets/images/mobile-how-it-works.png">
              <div class="winex-btn primary-btn h40 w120" @click="moveHowItWorks">더보기</div>
            </div>
          </div>
          <!--<div class="arrow-guide">
              우측으로 스크롤
              <img src="~@/assets/images/icon/ic-arrow-right-location.png"/>
          </div>-->
        </div>
      </div>

      <div class="content-wrap">
        <div class="future-map">
          <div class="title">
            Roadmap
          </div>
          <div class="content">
            <div class="image image-pc"><img src="~@/assets/images/road_map_pc_20220809.png"/></div>
            <div class="image image-tablet"><img src="~@/assets/images/road_map_tablet_20220809.png"/></div>
            <div class="image image-mo"><img src="~@/assets/images/road_map_mobile_20220809.png"/></div>
          </div>
        </div>
      </div>

      <div class="container-gray">
        <div class="content-wrap">
          <div class="winex-team">
            <div class="title">
              Winex Team
            </div>
            <div class="content">
              <div class="grid-items">
                <div class="item" v-for="teamMember in teamList" v-bind:key="teamMember.name">
                  <div class="image" :style="teamMember.image"></div>
                  <div class="text">
                    <div class="name">
                      {{teamMember.name}}
                    </div>
                    <div class="rank">
                      {{teamMember.rank}}
                    </div>
                    <ul class="feature">
                      <li v-for="feature in teamMember.featureList" v-bind:key="feature" v-html="feature"></li>
                    </ul>
                  </div>
                </div><!--/item-->
              </div>
            </div>
          </div>
        </div>
      </div> <!-- end team -->

      <div class="content-wrap">
        <div class="partners">
          <div class="title">
            Winex Partners
          </div>
          <div class="content">
            <div class="grid-items">
              <div class="item">
                <img src="~@/assets/images/partners/dhl.png"/>
              </div>
              <div class="item">
                <img src="~@/assets/images/partners/jiran.png"/>
              </div>
              <div class="item">
                <img src="~@/assets/images/partners/decent.png"/>
              </div>
              <div class="item">
                <img src="~@/assets/images/partners/axa.png"/>
              </div>
              <div class="item">
                <img src="~@/assets/images/partners/primus.jpg"/>
              </div>
              <div class="item">
                <img src="~@/assets/images/partners/JNGALLERY.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>
<script>
    export default {
        created() {

        },
        data () {
            return  {
              teamList: [
                {
                  image:{backgroundImage: `url(${require('@/assets/images/char/member01.png')})`},
                  name:"Charles Chahyoung Lee",
                  rank:"CEO, Founder",
                  featureList:[
                    "Yonsei Univ. Computer Science.",
                    "Serial Entrepreneur.",
                    "CEO&Founder of Playforum,<br/> Thatwine and Luolle"
                  ]
                },
                {
                  image:{backgroundImage: `url(${require('@/assets/images/char/member02.png')})`},
                  name:"Insoon Lee",
                  rank:"Winex Council for fine Wine",
                  featureList:[
                    "Yonsei Univ. Eng literature. Ph.D",
                    "Director of LeeInSoon Winelab",
                    "Former Director of WSA Academy",
                    "Founder of WSET in Korea"
                  ]
                },
                {
                  image:{backgroundImage: `url(${require('@/assets/images/char/member03.png')})`},
                  name:"Yunjin Choi",
                  rank:"Winex Council for fine Wine",
                  featureList:[
                    "Kyunghee Univ. Ph.D",
                    "Director of Vinojin company",
                    "Vice President of KISA",
                    "Adjunct Professor, Wine and<br/>Sommelier, Kyunghee Univ"
                  ]
                },
                {
                  image:{backgroundImage: `url(${require('@/assets/images/char/member04.png')})`},
                  name:"Coming Soon",
                  rank:"",
                  featureList:[

                  ]
                },
                {
                  image:{backgroundImage: `url(${require('@/assets/images/char/member05.png')})`},
                  name:"ChiYoung Oh",
                  rank:"Seed Investor & Incubator",
                  featureList:[
                    "CNU Computer Science",
                    "Jiran CDO & Founder",
                    "jiran.com"
                  ]
                },
                {
                  image:{backgroundImage: `url(${require('@/assets/images/char/member06.png')})`},
                  name:"SangSu Baek",
                  rank:"Seed Investor & Incubator",
                  featureList:[
                    "CNU Computer Science Ph.D",
                    "D'CENT CEO",
                    "dcentwallet.com"
                  ]
                },

                {
                  image:{backgroundImage: `url(${require('@/assets/images/char/member07.png')})`},
                  name:"Jaeyeol Ha",
                  rank:"Seed Investor & PM",
                  featureList:[
                    "SNU EECS Ph.D",
                    "Balancepole CEO"
                  ]
                },
                {
                  image:{backgroundImage: `url(${require('@/assets/images/char/member08.png')})`},
                  name:"Seongsoo Moon",
                  rank:"Development Partners",
                  featureList:[
                    "HKNU Computer Science.",
                    "Zzieut CEO & Founder",
                    "zzieut.com"
                  ]
                }
              ],
              advantageList: [
                {
                  icon:require("@/assets/images/icon/ic-wine.png"),
                  title:"프랑스 현지의 체계적인 와인보관",
                  titleMo:"현지의 체계적인 와인보관",
                  text:"투자가치 높은 파인와인을 프랑스의 와넥스 힐레브란트 창고에 보관합니다. 와인 전문 창고이므로 최적의 보관상태를 유지합니다."
                },
                {
                  icon:require("@/assets/images/icon/ic-database-t1.png"),
                  title:"합리적이고 경쟁력 있는 가격",
                  titleMo:"합리적이고 경쟁령 있는 가격",
                  text:"프랑스 수출가로 구매 가능하기 때문에 경쟁력과 투자 가치가 있습니다."
                },
                {
                  icon:require("@/assets/images/icon/ic-truck-t1.png"),
                  title:"와인의 실물 배송",
                  titleMo:"와인의 실물 배송",
                  text:"실물 와인은 NFT 홀더가 리딤 Redeem 요청을 하면 해당 와인을 언제 어디로든 원하는 장소로 배송받을 수 있습니다."
                },
                {
                  icon:require("@/assets/images/icon/ic-paper-check.png"),
                  title:"확실한 보증 시스템",
                  titleMo:"확실한 보증 시스템",
                  text:"와넥스의 파인 와인 NFT는 모두 와넥스의 창고에 보관 후 발행하며, 마지막 NFT 홀더가 리딤 요청을 하면 해당 와인을 배송받기에 완벽한 보증이 됩니다."
                },
                {
                  icon:require("@/assets/images/icon/ic-atom-t1.png"),
                  title:"다양하고 희소성 높은 소싱력",
                  titleMo:"다양하고 희소성 높은 소싱력",
                  text:"와넥스 플랫폼이 보증하는 프랑스 현지 와이너리와 네고시앙이 직접 수출가에 판매하여 일반적으로 구하기 힘든 파인 와인들을 손쉽게 구매/거래/판매 할 수 있습니다."
                },
                {
                  icon:require("@/assets/images/icon/ic-currency-eth-t1.png"),
                  title:"쉽고 빠른 그리고 안전한 거래",
                  titleMo:"쉽고 빠른, 안전한 거래",
                  text:"실물와인 거래에서 겪는 어려움인 진위 여부및 적정 보관 여부를 와넥스 플랫폼에서는 NFT거래만으로 해결 할 수 있습니다."
                }
              ]
            }
        },
        methods: {
          moveHowItWorks() {
            // TODO:: 나중에 FAQ 백엔드 붙으면 바꿔줘야 하는 부분
            this.$router.push('/cs/faq?no=9')
          }
        }
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/about.scss";
</style>
